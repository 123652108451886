const colors = {
    light: {
        bg: "#ffffff",
        topbar: "#1d1d1b",
        colorSelector: "#fcec00",
        logo: "#b8d04e",
        text: "#000000",
        textBg: "#ffffff",
        dragandrop: "#EDF5F6",
    },
    dark: {
        bg: "#1F2223",
        topbar: "#1d1d1b",
        colorSelector: "#fcec00",
        logo: "#b8d04e",
        text: "#ffffff",
        textBg: "#40474a",
        dragandrop: "#4e5152",
    },
};

export { colors };
